import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import { Col, Card, Alert } from "react-bootstrap";
import {SetBackgroundTheme} from "../../setBackgroundTheme";
import axios from 'axios';
import { baseURL } from "../../../baseURL";
import Cookies from 'js-cookie';

const Feedback = () => {
  const [topic, setTopic] = useState('General Questions');
  const [message, setMessage] = useState('For any inquiries about our services or website.');
  const [topicDescription, setTopicDescription] = useState('For any inquiries about our services or website.');
  const [sendingStatus, setSendingStatus] = useState(false);

  const [disabledButton, setDisabledButton] = useState(false);
  const [error, setError] = useState('');

  const { changeBackground } = useContext(ThemeContext);
  useEffect(() => {
    SetBackgroundTheme(changeBackground, Cookies);
  }, [changeBackground]);

  useEffect(() => {
    if(topic == 'General Questions') {
      setTopicDescription('For any inquiries about our services or website.')
    } else if(topic == 'Technical Support') {
      setTopicDescription("Report bugs, technical issues, or challenges you're facing.")
    } else if(topic == 'Account or Billing Questions') {
      setTopicDescription('Assistance with account settings, subscriptions, or payment-related concerns.');
    } else if(topic == 'Feature Requests') {
      setTopicDescription("Suggest new features or improvements you'd like to see.")
    } else if(topic == 'Other') {
      setTopicDescription('If your topic doesn’t fit into the above categories, let us know here.')
    }
  }, [topic]);

  async function sendFeedback() {
    setDisabledButton(true);

    const data = {
      token: Cookies.get('token'),
      email: Cookies.get('email'),
      topic: topic,
      message: message
    }

    try {
      const response = await axios.post(baseURL+'/api/send_feedback', data);
      if(response.data.status === 'ok') {
        setSendingStatus(true);
      } else {
        setError(response.data.text);
      }
    } catch(err) {

    }
    setDisabledButton(false);
  }

  return (
    <>
      <div className="row">

        <Col xl="12">
          <Card>
            <Card.Header className="d-block card-header">
              <h4 className="card-title">We Value Your Feedback</h4>
              <Card.Text className="m-0 subtitle">
                Share your thoughts, ask questions, or provide suggestions—we're here to listen!
              </Card.Text>
            </Card.Header>
            <Card.Body className="card-body">
            <div className="basic-form">
                <form onSubmit={(e) => e.preventDefault()}>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Your Email</label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        value={Cookies.get('email')}
                        disabled={true}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Topic</label>
                    <div className="col-sm-9">
                      <select className="form-control" onChange={(e) => setTopic(e.target.value)}>
                        <option value={'General Questions'}>General Questions</option>
                        <option value={'Technical Support'}>Technical Support</option>
                        <option value={'Account or Billing Questions'}>Account or Billing Questions</option>
                        <option value={'Feature Requests'}>Feature Requests</option>
                        <option value={'Other'}>Other</option>
                      </select>

                      <small>{topicDescription}</small>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Message</label>
                    <div className="col-sm-9">
                      <textarea
                        type="text"
                        className="form-control"
                        placeholder="Message"
                        rows={6}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label"></label>
                    <div className="col-sm-9">
                      {sendingStatus === false && (
                        <button className="btn btn-md btn-info" disabled={disabledButton} onClick={() => sendFeedback()}>
                          {disabledButton === false && (
                            <span>Send message</span>
                          )}
                          {disabledButton === true && (
                            <span>Loading...</span>
                          )}
                        </button>
                      )}

                      {sendingStatus === true && (
                        <Alert variant="success" className="mt-3 alert-dismissible fade show">
                          The message has been sent successfully.
                        </Alert>
                      )}

                      {error !== "" && (
                        <Alert variant="danger" className="mt-3 alert-dismissible fade show">
                          {error}
                        </Alert>
                      )}

                    </div>
                  </div>

                </form>
              </div>

            </Card.Body>
          </Card>
        </Col>
      </div>
    </>
  );
};

export default Feedback;
